@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'Rubik';
  color: #254cdd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Minter {
  max-width: 50vw;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: center;
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

.description, .instructions {
  text-align: center;
  color: black;
  font-size: 20px;
}

.title {
  color: black;
  text-align: left;
  font-size: 50px;
  font-weight: bold;
  border-bottom: 1px solid black;

  margin: 15px 50px;
  padding-bottom: 10px;
}

.subtitle {
  float: right;
  right: 0;
  font-size: 24px;
  margin-top: 30px;
}

ul {
  display: block;
}

li {
  color: black;
  display: inline-block;
  text-align: center;
  width: 230px;
  height: 150px;
  border: 2px solid #333;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

li .mint-title {
  font-size: 24px;
  display: block;
}

li .mintButton, .submit-button {
  display: table-cell; vertical-align: bottom;
  background-color: #333;
  color: white;
  border: none;
  width: 80px;
  height: 48px;
  font-size: 20px;
}

.submit-button {
  width: auto;
  height: 48px;
  max-height: 48px;
  margin: 30px auto;
  display: block;
}

img {
  width: 100%;
}

.description a {
  color: black;
  line-height: 24px;
}

.description img {
  width: 22px;
  vertical-align: middle;
}

.instructions {
  width: 50%;
  text-align: left;
  margin: 0 auto;
}

.feedback {
  text-align: center;
  color: black;
  font-size: 20px;
  line-height: 36px;
}

